import { makeStyles } from "@material-ui/core/styles";
import { MONTSERRAT_FONTS } from "../../../../constants/fontConsts";
const ProfileInformationStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fff",
    maxWidth: 600,
    width: "90%",
    maxHeight: 753,
    height: "90%",
    borderRadius: 20,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      borderRadius: 20,
    },
  },
  titleStepOneContainer: {
    paddingLeft: 80,
    paddingRight: 80,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
      marginTop: 30,
    },
  },
  titleStepTwoContainer: {
    paddingLeft: 80,
    paddingRight: 80,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 30,
      paddingRight: 30,
      marginTop: 30,
    },
  },
  modalTitle: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 20,
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  inputLabelName: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 14,
    marginBottom: 10,
    marginLeft: 5,
    marginTop: 20,
  },
  inputNoteMap: {
    width: 380,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 12,
    marginBottom: 10,
    marginLeft: 5,
    marginTop: -5,
    color: "gray",
    [theme.breakpoints.down("sm")]: {
      width: 350,
      fontSize: 11,
    },
    [theme.breakpoints.down(450)]: {
      width: 300,
    },
  },
  textCode: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 12,
    marginBottom: 10,
    marginLeft: 5,
    marginTop: -5,
    color: "gray",
    [theme.breakpoints.down("sm")]: {
      width: 350,
      fontSize: 11,
    },
    [theme.breakpoints.down(450)]: {
      width: 300,
    },
  },
  inputLabel: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    fontSize: 14,
    marginBottom: 10,
    marginLeft: 5,
    marginTop: 20,
    [theme.breakpoints.down(450)]: {
      fontSize: 12,
      marginBottom: 5,
      marginTop: 15,
    },
  },
  labelAvisoPrivacidad: {
    width: 400,
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_MEDIUM,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 20,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: 350,
      fontSize: 12,
    },
    [theme.breakpoints.down(450)]: {
      width: 250,
    },
  },
  avisoPrivacidad: {
    fontFamily: MONTSERRAT_FONTS.MONTSERRAT_BOLD,
    textDecoraction: "none",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      textDecoraction: "none",
      color: "#000",
    },
  },
  businessFormContainer: {
    marginTop: 10,
    paddingBottom: 50,
  },
  inputVariant: {
    borderRadius: 10,
    width: 400,
    height: 50,
    textAlign: "start",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
    [theme.breakpoints.down(450)]: {
      width: 300,
      height: 35,
    },
  },
  inputCode: {
    borderRadius: 10,
    width: 220,
    height: 50,
    background: "#fff",
    [theme.breakpoints.down(450)]: {
      height: 35,
    },
  },
  inputCodeCenter: {
    textAlign: "center",
  },
  renderedContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    float: "left",
    marginLeft: 50,
    marginBottom: 20,
    marginTop: 20,
    color: "#000 !important",
  },
  nextButton: {
    float: "right",
    marginRight: 50,
    marginBottom: 20,
    marginTop: 20,
    background: "#D15252",
    width: 120,
    "&.Mui-disabled": {
      background: "#adabab",
      color: "white",
    },
  },
  buttonsBottomContainer: {
    position: "sticky",
    height: 70,
    width: "100%",
    backgroundColor: "#F8F5EF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  locationsFormContainer: {
    paddingBottom: 50,
  },
  contentMap: {
    width: 400,
    height: 350,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: 350,
      height: 320,
    },
    [theme.breakpoints.down(450)]: {
      width: 300,
      height: 290,
    },
  },
  map: {
    height: "100%",
    borderRadius: 20,
  },
}));

export default ProfileInformationStyles;
