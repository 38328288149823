import React, { useEffect } from "react";
import {
  withScriptjs,
  GoogleMap,
  withGoogleMap,
  Marker,
} from "react-google-maps";

const Map = (props) => {
  const { setLocation, location, getCurrentPosition = false } = props;

  const defaultLocation = {
    lat: 20.639549680514968,
    lng: -103.43721458991723,
  };

  useEffect(() => {
    if (getCurrentPosition) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (e) => {}
      );
    }
  }, []);

  const handleMarker = (e) => {
    setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const onMapClick = (e) => {
    setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  return (
    <div>
      <GoogleMap
        zoom={location.lat === 0 ? 5 : 15}
        center={location.lat === 0 ? defaultLocation : location}
        onClick={onMapClick}
      >
        <Marker
          draggable={true}
          onDragEnd={(e) => handleMarker(e)}
          position={location.lat === 0 ? defaultLocation : location}
        />
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(Map));
