import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addDirection,
  checkReferenceCode,
  updateUser,
  verifyPostalCode,
} from "../../../../api/userApi";
import {
  setBusinessCompleteInformation,
  setBusinessLocationsInformation,
} from "../../../../store/reducers/business/BusinessInformationSlice";
import { setInfoCompleteStatus } from "../../../../store/reducers/user/UserInformationSlice";
import { notification } from "../../../../utils/notification";
import { getCookie } from "../../../../utils/sessionCookie";
import { validateZipCodes } from "../../../../utils/validations";
import CustomStepper from "../../../sharedComponents/CustomStepper";
import ProfileInformationStyles from "./ProfileInformationStyles";
import Loader from "../../../Loader";
import BusinessIcon from "@material-ui/icons/Business";
import MapIcon from "@material-ui/icons/Map";
import Map from "../../../sharedComponents/Map";
import { GENERAL_CONSTANTS } from "../../../../constants/credentials";

const ProfileInformationModal = (props) => {
  const { handleClose } = props;
  const classes = ProfileInformationStyles();
  //Redux
  const dispatch = useDispatch();

  const accessToken = getCookie("accessToken");
  //Stepper config
  const [activeStep, setActiveStep] = useState(0);
  //error states
  const [phoneError, setPhoneError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [paternalNameError, setpaternalNameError] = useState(false);
  const [maternalNameError, setMaternalNameError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  //error states
  const [zipCodeError, setZipCodeError] = useState(false);

  const [loader, setLoader] = useState(false);

  //Dialog
  const [openDialog, setOpenDialog] = useState(false);

  const steps = [
    {
      id: 1,
      label: "Llena la información de tu negocio",
      tag: "info",
      icon: BusinessIcon,
    },
    {
      id: 2,
      label: "Provee tu dirección principal",
      tag: "address",
      icon: MapIcon,
    },
  ];

  //states
  const [businessInformation, setBusinessInformation] = useState({
    user_name: "",
    paternal_lastname: "",
    maternal_lastname: "",
    telephone_number: "",
    business_name: "",
    reference_code: "",
  });

  const [locationsInformation, setlocationsInformation] = useState({
    id_postal_code: 0,
    alias: "",
    state: "",
    city: "",
    colony: "",
    street: "",
    ext_number: "",
    int_number: "",
    postal_code: null,
    references: "",
  });

  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const disabledButtonStepOne =
    businessInformation.user_name === "" ||
    businessInformation.paternal_lastname === "" ||
    businessInformation.maternal_lastname === "" ||
    phoneError ||
    businessInformation.telephone_number === "";

  const disabledButtonSecondStep =
    locationsInformation.city === "" ||
    locationsInformation.colony === "" ||
    locationsInformation.ext_number === "" ||
    locationsInformation.postal_code === null ||
    locationsInformation.state === "" ||
    locationsInformation.street === "" ||
    locationsInformation.alias === "" ||
    zipCodeError ||
    location.lat === 0 ||
    codeError;

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeBusinessInfo = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "telephone_number":
        let justNumbers = /^[0-9]+$/;
        if (
          !value.match(justNumbers) ||
          value.length > 10 ||
          value.length < 10
        ) {
          setPhoneError(true);
        } else {
          setPhoneError(false);
        }
        break;

      case "user_name":
        if (value === "") {
          setUserNameError(true);
        } else {
          setUserNameError(false);
        }
        break;
      case "paternal_lastname":
        if (value === "") {
          setpaternalNameError(true);
        } else {
          setpaternalNameError(false);
        }
        break;
      case "maternal_lastname":
        if (value === "") {
          setMaternalNameError(true);
        } else {
          setMaternalNameError(false);
        }
        break;
      case "reference_code":
        if (value !== "" && value.length !== 8) {
          setCodeError(true);
        } else {
          setCodeError(false);
        }
        break;

      default:
        break;
    }
    setBusinessInformation(() => {
      return {
        ...businessInformation,
        [name]: value,
      };
    });
  };

  const handleChangeLocationsInfo = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "postal_code":
        const isValidZipCode = validateZipCodes(value);
        if (!isValidZipCode) {
          setZipCodeError(true);
        } else {
          setZipCodeError(false);
        }
        break;

      default:
        break;
    }

    setlocationsInformation(() => {
      return {
        ...locationsInformation,
        [name]: value,
      };
    });
  };

  const handleDispatchBusinessInformation = () => {
    dispatch(setBusinessCompleteInformation(businessInformation));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleValidateReferenceCode = () => {
    setLoader(true);

    checkReferenceCode(businessInformation.reference_code)
      .then((res) => {
        if (res.data.correct_reference_code) {
          setCodeError(false);
          handleValidatePostalCode(res.data.reference_code_id);
        } else {
          notification("El código de referencia no es válido");
          setLoader(false);
        }
      })
      .catch((err) => {
        notification("Ocurrió un error al validar el código de referencia");
        setLoader(false);
      });
  };

  const handleValidatePostalCode = (referenceCodeId) => {
    setLoader(true);

    let payload = { postal_code: locationsInformation.postal_code };

    verifyPostalCode(payload)
      .then((res) => {
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "postal code is validated"
        ) {
          handleDispatchLocationsInformation(
            res.data.id_postal_code,
            referenceCodeId
          );
        } else {
          setLoader(false);
          setlocationsInformation({
            ...locationsInformation,
            id_postal_code: res.data.id_postal_code,
            reference_code_id: referenceCodeId,
          });
          setOpenDialog(true);
        }
      })
      .catch((err) => {
        notification("Ocurrió un error al guardar la información");
        setLoader(false);
      });
  };

  const handleDispatchLocationsInformation = async (id_pc, referenceCodeId) => {
    const busInfo = {
      business_info: {
        ...businessInformation,
        reference_code_id: referenceCodeId,
      },
    };
    updateUser(busInfo, accessToken)
      .then((res) => {
        if (res.data.status_Code === 201) {
          const busLocation = {
            business_location: {
              ...locationsInformation,
              id_postal_code: id_pc,
              latitude: location.lat,
              longitude: location.lng,
            },
          };
          addDirection(busLocation, accessToken)
            .then((resLocation) => {
              if (resLocation.data.status_Code === 201) {
                const newLocation = [
                  {
                    id_location: resLocation.data.new_id_location,
                    ...locationsInformation,
                    principal: true,
                    street_name: locationsInformation.street,
                    location_references: locationsInformation.references,
                    latitude: location.lat,
                    longitude: location.lng,
                  },
                ];
                dispatch(setBusinessLocationsInformation(newLocation));
                dispatch(setInfoCompleteStatus(1));
                setLoader(false);
                notification("Tus datos fueron guardados con éxito", "success");
                handleClose();
              } else if (res.data.status_Code === 401) {
                setLoader(false);
                notification("La sesión ha caducado");
              } else {
                setLoader(false);
                notification("Ocurrió un error al guardar la información");
              }
            })
            .catch((e) => {
              setLoader(false);
              notification("Ocurrió un error al guardar la información");
            });
        } else if (res.data.status_Code === 401) {
          setLoader(false);
          notification("La sesión ha caducado");
        } else {
          setLoader(false);
          notification("Ocurrió un error al guardar la información");
        }
      })
      .catch((e) => {
        setLoader(false);
        notification("Ocurrió un error al guardar la información");
      });
  };

  const handleNextStepper = () => {
    switch (activeStep) {
      case 0:
        handleDispatchBusinessInformation();
        break;
      case 1:
        businessInformation.reference_code !== ""
          ? handleValidateReferenceCode()
          : handleValidatePostalCode(0);
        break;
      default:
        break;
    }
  };
  const handleBackStepper = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderBusinessForm = () => (
    <Box className={classes.businessFormContainer}>
      <Box>
        <Typography className={classes.inputLabelName}> Nombre* </Typography>
        <TextField
          name="user_name"
          className={classes.inputVariant}
          InputProps={{
            className: classes.inputVariant,
          }}
          variant="outlined"
          onChange={(e) => handleChangeBusinessInfo(e)}
          value={businessInformation.user_name}
          error={userNameError}
          helperText={userNameError && "Este campo es requerido"}
        />

        <Typography className={classes.inputLabel}>
          Apellido Paterno*
        </Typography>
        <TextField
          value={businessInformation.paternal_lastname}
          name="paternal_lastname"
          className={classes.inputVariant}
          InputProps={{
            className: classes.inputVariant,
          }}
          variant="outlined"
          onChange={(e) => handleChangeBusinessInfo(e)}
          error={paternalNameError}
          helperText={paternalNameError && "Este campo es requerido"}
        />

        <Typography className={classes.inputLabel}>
          Apellido Materno*
        </Typography>
        <TextField
          value={businessInformation.maternal_lastname}
          name="maternal_lastname"
          className={classes.inputVariant}
          InputProps={{
            className: classes.inputVariant,
          }}
          variant="outlined"
          onChange={(e) => handleChangeBusinessInfo(e)}
          error={maternalNameError}
          helperText={maternalNameError && "Este campo es requerido"}
        />

        <Typography className={classes.inputLabel}>
          Nombre Empresa/Negocio
        </Typography>
        <TextField
          value={businessInformation.business_name}
          name="business_name"
          className={classes.inputVariant}
          InputProps={{
            className: classes.inputVariant,
          }}
          inputProps={{
            maxLength: 29,
          }}
          variant="outlined"
          onChange={(e) => handleChangeBusinessInfo(e)}
        />

        <Typography className={classes.inputLabel}> Teléfono* </Typography>
        <TextField
          value={businessInformation.telephone_number}
          name="telephone_number"
          className={classes.inputVariant}
          InputProps={{
            className: classes.inputVariant,
          }}
          inputProps={{
            maxLength: 10,
          }}
          variant="outlined"
          onChange={(e) => handleChangeBusinessInfo(e)}
          error={phoneError}
          helperText={phoneError && "Ingresa un número de teléfono correcto"}
        />
      </Box>
    </Box>
  );
  const renderLocationsForm = () => (
    <Box className={classes.locationsFormContainer}>
      <Typography className={classes.inputLabel}>
        {" "}
        Alias de la dirección*{" "}
      </Typography>
      <TextField
        name="alias"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        variant="outlined"
        value={locationsInformation.alias}
        onChange={(e) => handleChangeLocationsInfo(e)}
        placeholder="ej: Casa, Oficina, Local, etc."
      />
      <Typography className={classes.inputLabel}> Estado* </Typography>
      <TextField
        name="state"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        variant="outlined"
        value={locationsInformation.state}
        onChange={(e) => handleChangeLocationsInfo(e)}
      />

      <Typography className={classes.inputLabel}>Ciudad*</Typography>
      <TextField
        name="city"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        value={locationsInformation.city}
        variant="outlined"
        onChange={(e) => handleChangeLocationsInfo(e)}
      />

      <Typography className={classes.inputLabel}>Colonia*</Typography>
      <TextField
        name="colony"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        value={locationsInformation.colony}
        variant="outlined"
        onChange={(e) => handleChangeLocationsInfo(e)}
      />

      <Typography className={classes.inputLabel}>Calle*</Typography>
      <TextField
        name="street"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        variant="outlined"
        value={locationsInformation.street}
        onChange={(e) => handleChangeLocationsInfo(e)}
      />

      <Typography className={classes.inputLabel}> Número Exterior* </Typography>
      <TextField
        name="ext_number"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        inputProps={{
          maxLength: 5,
        }}
        variant="outlined"
        value={locationsInformation.ext_number}
        onChange={(e) => handleChangeLocationsInfo(e)}
      />

      <Typography className={classes.inputLabel}> Número Interior </Typography>
      <TextField
        name="int_number"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        inputProps={{
          maxLength: 5,
        }}
        variant="outlined"
        value={locationsInformation.int_number}
        onChange={(e) => handleChangeLocationsInfo(e)}
      />
      <Typography className={classes.inputLabel}> Código Postal* </Typography>
      <TextField
        name="postal_code"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        inputProps={{
          maxLength: 5,
        }}
        variant="outlined"
        value={locationsInformation.postal_code}
        onChange={(e) => handleChangeLocationsInfo(e)}
        error={zipCodeError}
        helperText={zipCodeError && "Ingresa un código correcto"}
      />

      <Typography className={classes.inputLabel}>
        Referencias del domicilio
      </Typography>
      <TextField
        name="references"
        className={classes.inputVariant}
        InputProps={{
          className: classes.inputVariant,
        }}
        inputProps={{
          maxLength: 20,
        }}
        variant="outlined"
        value={locationsInformation.references}
        onChange={(e) => handleChangeLocationsInfo(e)}
      />
      <Typography className={classes.inputLabel}>
        Marca la ubicación en el mapa*
      </Typography>
      <Typography className={classes.inputNoteMap}>
        Para seleccionar la ubicación en el mapa se debe mover el pin y
        colocarlo en el domicilio ingresado
      </Typography>
      <Map
        googleMapURL={GENERAL_CONSTANTS.URL_API_MAPS}
        containerElement={<Box className={classes.contentMap} />}
        mapElement={<Box className={classes.map} />}
        loadingElement={<p>cargando...</p>}
        setLocation={setLocation}
        location={location}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          marginBottom: 20,
        }}
      >
        <Typography className={classes.inputLabel}>
          Código de Referencia (Opcional)
        </Typography>
        <Typography className={classes.textCode}>
          Ingresa el código de referencia de un cliente Liber.
        </Typography>
        <TextField
          name="reference_code"
          className={classes.inputCode}
          InputProps={{
            className: classes.inputCode,
          }}
          inputProps={{
            className: classes.inputCodeCenter,
            maxLength: 8,
          }}
          variant="outlined"
          value={businessInformation.reference_code}
          onChange={(e) => handleChangeBusinessInfo(e)}
          error={codeError}
          helperText={
            codeError
              ? "El código no es válido"
              : "El código debe tener 8 carácteres"
          }
        />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography className={classes.labelAvisoPrivacidad}>
          {" "}
          Consiento que mis datos personales sean tratados de conformidad con
          los términos y condiciones informados en el presente{" "}
          <a
            className={classes.avisoPrivacidad}
            target="_blank"
            rel="noreferrer"
            href="https://assets-liber.s3.amazonaws.com/docs/legal_docs/Aviso_de_privacidad.pdf"
          >
            aviso de privacidad.
          </a>{" "}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Loader text="Guardando la información..." showLoader={loader} />
      <Box className={classes.mainContainer}>
        <Box
          className={
            activeStep === 0
              ? classes.titleStepOneContainer
              : classes.titleStepTwoContainer
          }
        >
          <Typography className={classes.modalTitle} align="center">
            Completa la siguiente información para poder continuar
          </Typography>
        </Box>
        <Box>
          <CustomStepper
            steps={steps}
            handleBack={handleBackStepper}
            handleNext={handleNextStepper}
            activeStep={activeStep}
          >
            <Box className={classes.renderedContent}>
              {activeStep === 0 ? renderBusinessForm() : renderLocationsForm()}
            </Box>
          </CustomStepper>
          <Box className={classes.buttonsBottomContainer}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBackStepper}
              className={classes.backButton}
            >
              Regresar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextStepper}
              className={classes.nextButton}
              disabled={
                activeStep === 0
                  ? disabledButtonStepOne
                  : disabledButtonSecondStep
              }
            >
              {activeStep === steps.length - 1 ? "¡Listo!" : "Siguiente"}
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Nota"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El código postal agregado por el momento no cuenta con servicio de
            recolección de la paquetería Liber, de igual forma podrá utilizar la
            plataforma para generar generar sus guías con las diferentes
            paqueterías que tenemos, entre ellas estan: (Liber, Estafeta, Fedex,
            J&T, entre otras). Una vez que el administrados agregue su código
            postal a la las zonas de cobertura podrá tener el servicio de
            recolección.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
          <Button
            onClick={() =>
              handleDispatchLocationsInformation(
                locationsInformation.id_postal_code,
                locationsInformation.reference_code_id
              )
            }
            autoFocus
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ProfileInformationModal;
