import axios from "axios";
import { getCookie } from "../utils/sessionCookie";
const { REACT_APP_LIBER_API_URL, REACT_APP_LIBER_HOST_URL } = process.env;

export const updateUser = (payload, accessToken) => {
  const request = {
    host: REACT_APP_LIBER_HOST_URL,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_LIBER_API_URL}/business/add-info`,
    headers: {
      "content-type": "application/json",
      auth_Token: accessToken,
    },
  };

  return axios(request);
};

export const addDirection = (payload, accessToken) => {
  const request = {
    host: REACT_APP_LIBER_HOST_URL,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_LIBER_API_URL}/business/add-location`,
    headers: {
      "content-type": "application/json",
      auth_Token: accessToken,
    },
  };

  return axios(request);
};

export const checkReferenceCode = (code) => {
  let payload = {
    refence_code: code,
  };

  const request = {
    host: REACT_APP_LIBER_HOST_URL,
    method: "POST",
    data: payload,
    body: JSON.stringify(payload),
    url: `${REACT_APP_LIBER_API_URL}/business/check-reference-code`,
    headers: {
      "content-type": "application/json",
      auth_Token: getCookie("accessToken"),
    },
  };

  return axios(request);
};

export const getInfoNegocio = (accessToken) => {
  const request = {
    host: REACT_APP_LIBER_HOST_URL,
    method: "GET",
    url: `${REACT_APP_LIBER_API_URL}/business/get-complete-info`,
    headers: {
      "content-type": "application/json",
      auth_Token: accessToken,
    },
  };

  return axios(request);
};

export const verifyPostalCode = (payload) => {
  const request = {
    method: "POST",
    url: `${REACT_APP_LIBER_API_URL}/zones/check-existence-postal-code`,
    data: payload,
    body: JSON.stringify(payload),
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(request);
};
