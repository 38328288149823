import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import React, { useEffect } from "react";
import FormBottomButtons from "../FormBottomButtons";
import DestinationInformationStyles from "./OriginInformationStyles";
import { notification } from "../../../utils/notification";
import { getLocation, getRecipients } from "../../../api/guidesFormApi";
import useLogout from "../../../hooks/useLogout";
import { Autocomplete } from "@material-ui/lab";

const DestinationInformation = (props) => {
  const {
    handleClickBackStep,
    handleClickNextStep,
    recipient_info,
    setRecipientInfo,
    recipients,
    setRecipients,
    valueRecipient,
    setValueRecipient,
    verifyCode,
    setVerifyCode,
    verifyPhone,
    setVerifyPhone,
    recipientSelected,
    setRecipientSelected,
  } = props;
  const classes = DestinationInformationStyles();

  const [logout] = useLogout();

  const disableButtton =
    recipient_info.full_name === "" ||
    recipient_info.state === "" ||
    recipient_info.city === "" ||
    !verifyPhone ||
    recipient_info.colony === "" ||
    recipient_info.street_name === "" ||
    recipient_info.ext_number === "" ||
    !verifyCode;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (recipient_info.full_name === "") {
      getRecipients()
        .then((res) => {
          if (res.data.status_Code === 200) {
            setRecipients(res.data.concurrent_recipients);
          } else if (res.data.status_Code === 401) {
            notification("La sesión ha caducado", "error");
            logout();
          } else {
            notification("No se pudieron recuperar los destinatarios");
          }
        })
        .catch((err) => {
          notification("No se pudieron recuperar los destinatarios");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveRecipient = (e) => {
    setRecipientInfo((prevState) => ({
      ...prevState,
      save_recipient: e.target.checked,
      update_recipient: false,
    }));
  };

  const handleUpdateRecipient = (e) => {
    setRecipientInfo((prevState) => ({
      ...prevState,
      save_recipient: false,
      update_recipient: e.target.checked,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecipientInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "postal_code") {
      let justNumbers = /^[0-9]+$/;
      if (value.length === 5 && value.match(justNumbers)) {
        setVerifyCode(true);
      } else {
        setVerifyCode(false);
      }
    }
    if (name === "telephone_number") {
      let justNumbers = /^[0-9]+$/;
      if (
        !value.match(justNumbers) ||
        value.length > 10 ||
        value.length < 10 ||
        value === "0000000000"
      ) {
        setVerifyPhone(false);
      } else {
        setVerifyPhone(true);
      }
    }
  };

  const onChangeOptionIndex = (value) => {
    if (value !== null) {
      setVerifyCode(true);

      let justNumbers = /^[0-9]+$/;
      if (
        !value.telephone_number.match(justNumbers) ||
        value.telephone_number.length > 10 ||
        value.telephone_number.length < 10 ||
        value.telephone_number === "0000000000"
      ) {
        setVerifyPhone(false);
      } else {
        setVerifyPhone(true);
      }

      setRecipientInfo({
        id_recipient: value.id_concurrent_recipient,
        full_name: value.full_name,
        telephone_number: value.telephone_number,
        state: value.state,
        city: value.city,
        colony: value.colony,
        street_name: value.street_name,
        ext_number: value.external_number,
        int_number: value.internal_number,
        postal_code: value.postal_code,
        location_references: value.location_references,
        latitude: value.latitude,
        longitude: value.longitude,
        save_recipient: false,
        update_recipient: false,
      });
    } else {
      setVerifyCode(false);
      setVerifyPhone(false);

      setRecipientInfo({
        id_recipient: "",
        full_name: "",
        telephone_number: "",
        state: "",
        city: "",
        colony: "",
        street_name: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        location_references: "",
        latitude: 0,
        longitude: 0,
        save_recipient: false,
        update_recipient: false,
      });
    }

    setValueRecipient(value);
  };

  const handleNext = async () => {
    setRecipientSelected({
      state: recipient_info.state,
      city: recipient_info.city,
      street_name: recipient_info.street_name,
      ext_number: recipient_info.ext_number,
      postal_code: recipient_info.postal_code,
      colony: recipient_info.colony,
      isSelected: true,
    });

    let validateRecipient =
      valueRecipient?.street_name === recipient_info.street_name &&
      valueRecipient?.external_number === recipient_info.ext_number &&
      valueRecipient?.city === recipient_info.city &&
      valueRecipient?.state === recipient_info.state &&
      valueRecipient?.colony === recipient_info.colony &&
      valueRecipient?.postal_code === recipient_info.postal_code;

    let validate =
      recipientSelected.street_name === recipient_info.street_name &&
      recipientSelected.ext_number === recipient_info.ext_number &&
      recipientSelected.city === recipient_info.city &&
      recipientSelected.state === recipient_info.state &&
      recipientSelected.colony === recipient_info.colony &&
      recipientSelected.postal_code === recipient_info.postal_code;

    if (recipientSelected.isSelected) {
      if (valueRecipient === null) {
        if (!validate || recipient_info.latitude === 0) {
          await getLocationRecipient();
        }
      } else {
        if (!validateRecipient || recipient_info.latitude === 0) {
          await getLocationRecipient();
        }
      }
    } else {
      if (valueRecipient === null) {
        if (recipient_info.latitude === 0) {
          await getLocationRecipient();
        }
      } else {
        if (!validateRecipient || recipient_info.latitude === 0) {
          await getLocationRecipient();
        }
      }
    }

    handleClickNextStep();
  };

  const getLocationRecipient = async () => {
    let payloadLocation = {
      street: recipient_info.street_name,
      street_number: recipient_info.ext_number,
      city: recipient_info.city,
      state: recipient_info.state,
      colony: recipient_info.colony,
      postal_code: recipient_info.postal_code,
    };

    await getLocation(payloadLocation)
      .then((res) => {
        console.log(res.data);
        if (
          res.data.status_Code === 200 &&
          res.data.status_Message === "Longitude y latitude exists"
        ) {
          setRecipientInfo((prevState) => ({
            ...prevState,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
          }));
        } else if (res.data.status_Code === 401) {
          notification("La sesión ha caducado");
          logout();
        }
      })
      .catch((err) => {});
  };

  console.log(disableButtton);
  

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12}>
        <Typography className={classes.container}>
          Agregue información de destinatario
        </Typography>
      </Grid>
      {recipients.length > 0 && (
        <Grid item xs={12}>
          <FormControl className={classes.selectDirection}>
            <Autocomplete
              id="recipients"
              className={classes.styleAutocomplete}
              options={recipients}
              value={valueRecipient}
              getOptionLabel={(option) =>
                `${option.full_name}, ${option.street_name} #${
                  option.external_number
                }${
                  option.internal_number === ""
                    ? ""
                    : " Interior. " + option.internal_number
                }, ${option.colony}, CP. ${option.postal_code}, ${
                  option.city
                }, ${option.state}`
              }
              renderOption={(option) =>
                `Nombre: ${option.full_name} Domicilio: ${
                  option.street_name
                } #${option.external_number}${
                  option.internal_number === ""
                    ? ""
                    : " Interior. " + option.internal_number
                }, ${option.colony}, CP. ${option.postal_code}, ${
                  option.city
                }, ${option.state}`
              }
              onChange={(event, newValue) => onChangeOptionIndex(newValue)}
              classes={{
                option: classes.optionAutocomplete,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona un destinatario"
                  variant="outlined"
                  className={classes.textFileAutocomplete}
                  inputProps={{
                    ...params.inputProps,
                    className: classes.inputAutocomplete,
                  }}
                  InputLabelProps={{
                    className: classes.labelAutocomplete,
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            Nombre <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.full_name}
            name="full_name"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
              maxLength: 29,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            Teléfono <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.telephone_number}
            error={!verifyPhone && recipient_info.telephone_number !== ""}
            helperText={
              !verifyPhone && recipient_info.telephone_number !== ""
                ? "Ingresa un número de teléfono correcto"
                : ""
            }
            name="telephone_number"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
              maxLength: 10,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            Colonia <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.colony}
            name="colony"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            {" "}
            Calle <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.street_name}
            name="street_name"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.smallTextField}>
          <Typography className={classes.inputDescription}>
            Código Postal <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.postal_code}
            name="postal_code"
            onChange={handleChange}
            error={!verifyCode && recipient_info.postal_code !== ""}
            helperText={
              !verifyCode &&
              recipient_info.postal_code !== "" &&
              "Ingresa un código postal válido"
            }
            inputProps={{
              className: classes.inputTextFile,
              maxLength: 5,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.smallTextField}>
          <Typography className={classes.inputDescription}>
            Número Externo <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.ext_number}
            name="ext_number"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
              maxLength: 5,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.smallTextField}>
          <Typography className={classes.inputDescription}>
            Número Interno
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.int_number}
            name="int_number"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
              maxLength: 5,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.mediumTextField}>
          <Typography className={classes.inputDescription}>
            Ciudad <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.city}
            name="city"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            Estado <strong style={{ color: "red" }}>*</strong>
          </Typography>
          <TextField
            variant="outlined"
            value={recipient_info.state}
            name="state"
            onChange={handleChange}
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.mainFormContainer}>
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            {" "}
            Referencias del domicilio{" "}
          </Typography>
          <TextField
            variant="outlined"
            multiline
            value={recipient_info.location_references}
            name="location_references"
            onChange={handleChange}
            className={classes.textFieldMultiline}
            inputProps={{
              className: classes.inputTextFieldMultiline,
              maxLength: 20,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.mainFormContainer}>
        {recipient_info.id_recipient === "" ? (
          <FormControlLabel
            control={
              <Switch
                checked={recipient_info.save_recipient}
                onChange={handleSaveRecipient}
                name="guardar"
              />
            }
            labelPlacement="start"
            label={
              <Typography className={classes.switch}>
                Guardar destinatario
              </Typography>
            }
          />
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={recipient_info.update_recipient}
                onChange={handleUpdateRecipient}
                name="actualizar"
              />
            }
            labelPlacement="start"
            label={
              <Typography className={classes.switch}>
                Actualizar destinatario
              </Typography>
            }
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.mainFormContainer}>
        <FormBottomButtons
          disableNext={disableButtton}
          handleBack={handleClickBackStep}
          //handleNext={handleClickNextStep}
          handleNext={handleNext}
        />
      </Grid>
    </Grid>
  );
};

export default DestinationInformation;
