import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  Zoom,
  Box,
} from "@material-ui/core";
import React, { useEffect } from "react";
import OriginInformationStyles from "./OriginInformationStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { selectBusinessLocations } from "../../../store/reducers/business/BusinessInformationSlice";
import FormBottomButtons from "../FormBottomButtons";

const OriginInformation = (props) => {
  const classes = OriginInformationStyles();
  const {
    handleClickNextStep,
    selectedLocation,
    setSelectedLocation,
    enableDirty,
  } = props;
  const businessLocations = useSelector(selectBusinessLocations);
  const aliases = businessLocations.map((obj) => obj.alias);

  const [primLoc] = businessLocations.filter((loc) => loc.principal);

  useEffect(() => {
    if (selectedLocation.id_location !== "") {
      setSelectedLocation({
        alias: selectedLocation.alias,
        city: selectedLocation.city,
        colony: selectedLocation.colony,
        ext_number: selectedLocation.ext_number,
        id_location: selectedLocation.id_location,
        int_number: selectedLocation.int_number,
        location_references: selectedLocation.location_references,
        postal_code: selectedLocation.postal_code,
        principal: selectedLocation.principal,
        state: selectedLocation.state,
        street_name: selectedLocation.street_name,
      });
    } else if (aliases.length > 0) {
      setSelectedLocation({
        alias: primLoc.alias,
        city: primLoc.city,
        colony: primLoc.colony,
        ext_number: primLoc.ext_number,
        id_location: primLoc.id_location,
        int_number: primLoc.int_number,
        location_references: primLoc.location_references,
        postal_code: primLoc.postal_code,
        principal: primLoc.principal,
        state: primLoc.state,
        street_name: primLoc.street_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleAliasDropdownChange = async (e) => {
    const field = e.target.value;
    const location = await businessLocations.filter(
      (location) => location.alias === field
    );

    const {
      alias,
      city,
      colony,
      ext_number,
      id_location,
      int_number,
      location_references,
      postal_code,
      principal,
      state,
      street_name,
    } = location[0];

    setSelectedLocation({
      alias,
      city,
      colony,
      ext_number,
      id_location,
      int_number,
      location_references,
      postal_code,
      principal,
      state,
      street_name,
    });
    enableDirty();
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12}>
        <Typography className={classes.container}>
          Selecciona la dirección de origen
        </Typography>
      </Grid>
      {/* Mostramos este drowpdown unicamente si hay más de una dirección */}
      {aliases.length > 0 && (
        <Grid item xs={12}>
          <FormControl className={classes.selectDirection}>
            <Select
              variant="outlined"
              onChange={(e) => {
                handleAliasDropdownChange(e);
              }}
              IconComponent={ExpandMoreIcon}
              className={classes.styleSelect}
              defaultValue={
                selectedLocation.alias !== ""
                  ? selectedLocation.alias
                  : primLoc.alias
              }
            >
              {aliases.map((alias) => (
                <MenuItem
                  key={alias}
                  value={alias}
                  className={classes.selectItem}
                >
                  {alias}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>Colonia</Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.colony}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}> Calle </Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.street_name}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.smallTextField}>
          <Typography className={classes.inputDescription}>
            Código Postal
          </Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.postal_code}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.smallTextField}>
          <Typography className={classes.inputDescription}>
            {" "}
            Número Externo{" "}
          </Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.ext_number}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.smallTextField}>
          <Typography className={classes.inputDescription}>
            {" "}
            Número Interno{" "}
          </Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.int_number}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.mediumTextField}>
          <Typography className={classes.inputDescription}> Ciudad </Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.city}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.mainFormContainer}
      >
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}> Estado </Typography>
          <TextField
            variant="outlined"
            value={selectedLocation.state}
            disabled
            inputProps={{
              className: classes.inputTextFile,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.mainFormContainer}>
        <FormControl className={classes.largeTextField}>
          <Typography className={classes.inputDescription}>
            Referencias del domiclio{" "}
          </Typography>
          <TextField
            variant="outlined"
            multiline
            value={selectedLocation.location_references}
            disabled
            className={classes.textFieldMultiline}
            inputProps={{
              className: classes.inputTextFieldMultiline,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={classes.mainFormContainer}>
        <FormBottomButtons handleNext={handleClickNextStep} />
      </Grid>
    </Grid>
  );
};

export default OriginInformation;
